const kalenderCjjbf = [
    {
        id: 1,
        title: 'WORLD CHAMPIONSHIP COMBAT JU-JUTSU',
        dateEvent: '28 November - 1 December 2024',
        location: 'Mikolow, Poland',
        hyperlink: 'https://fitofan.com/e374687549'
    },
    {
        id: 2,
        title: 'AFRICAN CHAMPIONSHIP COMBAT JU-JUTSU',
        dateEvent: '23 August - 25 August 2024',
        location: 'Accra, Ghana',
        hyperlink: 'http://www.cjjif.org/events/african2024GH.pdf'
    },
    {
        id: 3,
        title: 'EUROPEAN CHAMPIONSHIP COMBAT JU-JUTSU',
        dateEvent: '17 May - 19 May 2024',
        location: 'Malgrat de Mar, Spain',
        hyperlink: 'https://www.facebook.com/media/set/?set=oa.890022613153364&type=3'
    },
];

export default kalenderCjjbf;